import React from "react";
import { Security } from "../components";
import Layout from "../components/layout";

const SecurityPage = () => {
	return <Layout>
	<Security />
</Layout>
};

export default SecurityPage;
